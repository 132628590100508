import React, { useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import OrderContext from "./context/OrderContext";

const OrderStepNav = () => {
  const { step, setStep } = useContext(OrderContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { id } = useParams<UpdateEventParams>() as UpdateEventParams;
  type UpdateEventParams = {
    id: string;
  };

  const currentLocation = (path?: string) => {
    const isLocationIncludesShop = location.pathname.includes("shop");
    const splitLocation = location.pathname.split("/");

    if (isLocationIncludesShop) {
      splitLocation.splice(-1);

      return splitLocation.join("/");
    } else {
      splitLocation.push(path || "");
      return splitLocation.join("/");
    }
  };

  return (
    <ul id="order-nav-step" className="w-full col-span-2 steps ">
      <li
        className={`step after:cursor-pointer after:shadow-md after:content-[counter(step)] ${
          step > 0 && "step-primary"
        }`}
        onClick={() => {
          setStep(1);
          navigate("/event/" + id);
        }}
      >
        Select Ticket
      </li>
      <li
        className={`step after:cursor-pointer after:shadow-md after:content-[counter(step)] ${
          step > 1 && "step-primary"
        }`}
        onClick={() => {
          setStep(2);
          navigate(currentLocation("shop"));
        }}
      >
        Shop
      </li>
      <li
        className={`step after:cursor-pointer after:shadow-md after:content-[counter(step)] ${
          step > 2 && "step-primary"
        }`}
        onClick={() => {
          setStep(3);
          navigate(currentLocation());
        }}
      >
        Checkout
      </li>
    </ul>
  );
};

export default OrderStepNav;
