import dayjs from "dayjs";
import React from "react";
import Error from "../../components/Error";
import Loading from "../../components/Loading";
import { Event } from "../../models/orderEventTickets.model";
import { useGetOrdersQuery } from "../../services/order.api";

interface ComponentProps {
  handleOpen: (datas: any, orderNumber: number) => void;
}

const Order: React.FC<ComponentProps> = ({ handleOpen }) => {
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(80);

  const {
    data: userOrders,
    isLoading,
    isError,
  } = useGetOrdersQuery({
    skip,
    take,
  });

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="p-1 lg:px-6 lg:py-4">
      <div className="font-bold lg:text-[18px] text-[16px]">MY ORDERS</div>
      <div className="grid grid-cols-2 gap-3 p-1 lg:px-4 lg:py-2 lg:gap-4 md:grid-cols-4">
        {userOrders &&
          userOrders?.data.map((order, index) => {
            return order.events.map((o: Event) => {
              return (
                <div className="overflow-hidden rounded-sm drop-shadow">
                  <div
                    className="w-full h-[120px] lg:h-[220px] bg-no-repeat bg-center bg-cover"
                    style={{
                      backgroundImage: `url(${
                        o ? process.env.REACT_APP_IMAGE_BASE_URL + o.image : ""
                      })`,
                    }}
                  />
                  <div className="p-2">
                    <p className="text-[22px] md:text-[24px] font-semibold">
                      {o.name}
                    </p>
                    <p className="text-[16px] md:text-[18px]">
                      {dayjs(o.dateTime).format("MMM DD, YYYY")}
                    </p>
                    <p className="text-[16px] md:text-[18px]">{o.venue.name}</p>
                    <p className="text-[16px] md:text-[18px]">Order#: {order.orderNumber}</p>
                    <button
                      onClick={() => {
                        handleOpen(o, order.orderNumber);
                      }}
                      className="w-full p-1 lg:p-2 mt-3 font-medium tracking-wider text-white uppercase rounded-sm lg:rounded-sm bg-blue-1100 text-[12px] lg:text-[14px]"
                    >
                      View Tickets
                    </button>
                  </div>
                </div>
              );
            });
          })}
        {/* {userOrders &&
            userOrders?.data.map((order, index) => {

              return order.orderEventTickets.map((o: OrderEventTicket) => {
                return (
                  <div>
                    <p>Event Name: {o.eventTicket.event.name}</p>
                    <p>Ticket Name: {o.eventTicket.name}</p>
                    <p>Section: {o.eventTicket.section}</p>
                    <p>Row: {o.eventTicket.row}</p>
                    <p>Order #: {order.id}</p>

                    <img
                      className="object-cover h-32"
                      src={
                        o
                          ? process.env.REACT_APP_IMAGE_BASE_URL +
                            o.eventTicket.event.image?.fileName
                          : ""
                      }
                    />
                  </div>
                );
              });
            })} */}
      </div>
      {/* HISTORY */}
      {/* <div className="mt-2 font-bold">HISTORY</div>
        <div className="grid grid-cols-2 gap-4 px-4 py-2 md:grid-cols-4">
          {historyDatas.map((h, index) => {
            return (
              <div className="drop-shadow" key={index}>
                <div>
                  <img
                    src={h.image}
                    className="object-cover h-32"
                    alt="history"
                  />
                </div>
                <div className="p-2">
                  <div className="font-bold">{h.title}</div>
                  <div className="font-light">{h.date}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-center my-6">
          <div>
            <button className="w-48 p-2 text-white uppercase rounded-sm drop-shadow bg-blue-1100">
              See more
            </button>
          </div>
        </div> */}
    </div>
  );
};

export default Order;
