import React from "react";
import { FieldProps } from "formik";

interface CustomInputProps {
  type?: string;
  placeholder?: string;
}

const CustomInput: React.FC<CustomInputProps & FieldProps> = ({
  field,
  form: { touched, errors },
  type,
  placeholder,
  ...props
}) => {
  return (
    <div className="pt-4">
      <input
        className={`${
          touched[field.name] && errors[field.name]
            ? "w-96 h-10 p-2 text-sm drop-shadow ring ring-offset-0 ring-red-500 rounded focus:outline-none"
            : "w-96 h-10 p-2 text-sm drop-shadow outline-none ring ring-offset-0 rounded ring-gray-100 focus:outline-none"
        }`}
        placeholder={placeholder}
        type={type}
        {...field}
        {...props}
      />
      <div className="pt-2 text-sm text-red-500">
        <p className="w-96">{touched[field.name] && errors[field.name] && <>{errors[field.name]}</>}</p>
      </div>
    </div>
  );
};

export default CustomInput;
