import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

// export interface CustomError {
//   data: {
//     message: [string];
//   };
// }

// interface Events {
//   data: Event[];
//   skip: number;
//   take: number;
//   count: number;
// }

// interface EventGroups {
//   data: EventGroup[];
//   skip: number;
//   take: number;
//   count: number;
// }

// interface Query {
//   skip?: number;
//   take?: number;
//   search?: string;
// }

export const healthApi = createApi({
  reducerPath: "healthApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/health",
    //   }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  }) as BaseQueryFn,
  tagTypes: [],
  endpoints: (builder) => ({
    getHealth: builder.query({
      query: () => ({
        url: "/",
      }),
    }),
  }),
});

export const { useGetHealthQuery } = healthApi;
