import { RootState } from "./../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import {
  CreateCreditCardPayment,
  DebitCardPayment,
  InitDebitCardPayment,
  InitEWalletPayment,
  InitEWalletPaymentResponse,
  InitOnlineDebitPaymentResponse,
  OnlineDebitPaymentResponse,
  Payment,
  PaypalPaymentInitialization,
  PaypalPaymentInitResponse,
  ValidateDebitPaymentOtp,
} from "../models/payment.model";

export interface CustomError {
  data: {
    message: string[];
  };
}

export const paymentApi = createApi({
  reducerPath: "paymentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/user/payments",
    prepareHeaders: (headers, { getState, endpoint }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  endpoints: (builder) => ({
    createCreditCardPayment: builder.mutation<Payment, CreateCreditCardPayment>(
      {
        query: (body) => ({
          url: "credit-card",
          method: "POST",
          body,
        }),
      }
    ),
    createEWalleyPayment: builder.mutation<Payment, CreateCreditCardPayment>({
      query: (body) => ({
        url: "e-wallet",
        method: "POST",
        body,
      }),
    }),
    initEWalleyPayment: builder.mutation<
      InitEWalletPaymentResponse,
      InitEWalletPayment
    >({
      query: (body) => ({
        url: "e-wallet/init",
        method: "POST",
        body,
      }),
    }),
    initDebitCardPayment: builder.mutation<
      InitOnlineDebitPaymentResponse,
      InitDebitCardPayment
    >({
      query: (body) => ({
        url: "debit-card/init",
        method: "POST",
        body,
      }),
    }),
    debitCardPayment: builder.mutation<
      OnlineDebitPaymentResponse,
      DebitCardPayment
    >({
      query: (body) => ({
        url: "debit-card",
        method: "POST",
        body,
      }),
    }),
    validateDebitOtp: builder.mutation<any, ValidateDebitPaymentOtp>({
      query: (body) => ({
        url: "debit-card/otp",
        method: "POST",
        body,
      }),
    }),
    gcashPayment: builder.mutation({
      query: (body) => ({
        url: "v1/sources",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Basic cGtfdGVzdF91ZnVXOFdOa1JGTEhjSDZTcFlhRzFvWTE6c2tfdGVzdF9waDFLU2RUUm9NS0NOUmFWRnBpRFlzVUs=`,
        },
        body,
      }),
    }),
    getGcashPayment: builder.mutation({
      query: (id) => ({
        url: `v1/sources/${id}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Basic cGtfdGVzdF91ZnVXOFdOa1JGTEhjSDZTcFlhRzFvWTE6c2tfdGVzdF9waDFLU2RUUm9NS0NOUmFWRnBpRFlzVUs=`,
        },
      }),
    }),
    paypalInit: builder.mutation<
      PaypalPaymentInitResponse,
      PaypalPaymentInitialization
    >({
      query: (body) => ({
        url: `paypal`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGcashPaymentMutation,
  useGetGcashPaymentMutation,
  useCreateCreditCardPaymentMutation,
  useInitEWalleyPaymentMutation,
  useInitDebitCardPaymentMutation,
  useDebitCardPaymentMutation,
  useValidateDebitOtpMutation,
  usePaypalInitMutation,
} = paymentApi;
