import { RootState } from "./../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import {
  CreateOrder,
  CreateOrderResponse,
  CreateProductOrder,
  DeleteProductOrderVariant,
  GetOrderResponse,
  Order,
  UpdateProductOrder,
} from "../models/order.model";

export interface CustomError {
  data: {
    message: string;
  };
  status: number;
}

interface Orders {
  data: Order[];
  skip: number;
  take: number;
}

interface GetOrder {
  id: string;
}

interface UserOrders {
  skip?: number;
  take?: number;
}

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/user/orders",
    prepareHeaders: (headers, { getState, endpoint }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Order"],
  endpoints: (builder) => ({
    createOrder: builder.mutation<CreateOrderResponse, CreateOrder>({
      query: (body) => {
        return {
          url: "",
          method: "POST",
          body,
        };
      },
    }),
    addProductOrder: builder.mutation<CreateOrderResponse, CreateProductOrder>({
      query: ({ orderId, ...body }) => {
        return {
          url: `/${orderId}/add-product-order`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Order"]
    }),
    updateProductOrder: builder.mutation<CreateOrderResponse, UpdateProductOrder>({
      query: ({ orderId, productVariantOrderid, ...body }) => {
        return {
          url: `/${orderId}/product-variant-order/${productVariantOrderid}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["Order"]
    }),
    deleteProductOrderVariant: builder.mutation<boolean, DeleteProductOrderVariant>({
      query: ({ orderId, productVariantOrderid }) => {
        return {
          url: `/${orderId}/product-variant-order/${productVariantOrderid}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Order"]
    }),
    getOrders: builder.query<Orders, UserOrders>({
      query: ({ take, skip }) => {
        return {
          url: "",
          params: {
            skip,
            take,
          },
        };
      },
    }),
    getOrder: builder.query<GetOrderResponse, GetOrder>({
      query: ({ id }) => {
        return {
          url: `${id}`,
        };
      },
      providesTags: ["Order"]
    }),
  }),
});

export const { useCreateOrderMutation, useGetOrdersQuery, useGetOrderQuery, useAddProductOrderMutation, useUpdateProductOrderMutation, useDeleteProductOrderVariantMutation } =
  orderApi;
