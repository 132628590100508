import React from "react";

let currentOTPIndex: number = 0;

interface ComponentProps {
  onSubmit: (otp: string) => void;
  otpCharacters?: number;
}

const OTPValidation: React.FC<ComponentProps> = ({
  onSubmit,
  otpCharacters = 6,
}) => {
  const [otp, setOtp] = React.useState<string[]>(
    new Array(otpCharacters).fill("")
  );
  const [activeOTPIndex, setActiveOTPIndex] = React.useState<number>(0);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target;
    const newOTP: string[] = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);

    if (!value) setActiveOTPIndex(currentOTPIndex - 1);
    else setActiveOTPIndex(currentOTPIndex + 1);

    setOtp(newOTP);
  };

  const handleKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    currentOTPIndex = index;
    if (key === "Backspace") {
      setActiveOTPIndex(currentOTPIndex - 1);
    }
  };
  React.useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  //Handling resend OTP and verify

  //   const { userDetail } = useAppSelector(authSelect);
  //   const navigate = useNavigate();
  const [counter, setCounter] = React.useState(300);

  React.useEffect(() => {
    counter > 1 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <div className="flex flex-col items-center">
      <div className="flex items-center justify-center mt-4 mb-6 space-x-2 lg:mt-0">
        {otp.map((_, index) => {
          return (
            <React.Fragment key={index}>
              <input
                ref={index === activeOTPIndex ? inputRef : null}
                type="number"
                className="w-12 text-xl font-semibold text-center text-gray-400 transition bg-transparent border-2 border-gray-400 rounded outline-none h-14 focus:border-gray-700 focus:text-gray-700 spin-button-none"
                onChange={handleChange}
                onKeyDown={(e) => handleKeyDown(e, index)}
                value={otp[index]}
              />
            </React.Fragment>
          );
        })}
      </div>
      <button
        onClick={() => onSubmit(otp.join(""))}
        className="p-2 font-bold text-white uppercase rounded-sm bg-blue-1100 lg:w-96 w-72"
      >
        Proceed
      </button>
    </div>
  );
};

export default OTPValidation;
