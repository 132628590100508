import React from "react";
import ProfilePic from "../../assets/images/DefaultProfile.png";
// import { Logout } from "../../features";
import { Modal } from "../../components/Modal";
import { Ticket } from "../../models/ticket.model";
import QRCode from "react-qr-code";
import { useAppSelector } from "../../app/hooks";
import { authSelect } from "../../features/Authentication/authSlice";
import Order from "../../features/Order";
import dayjs from "dayjs";
import SearchInput from "../../components/Search";

const ProfilePage = () => {
  const [open, setOpen] = React.useState(false);
  const { user, accessToken } = useAppSelector(authSelect);
  const [ticketData, setTicketData] = React.useState([]);
  const [eventData, setEventData] = React.useState<{
    name: string;
    organizationName: string;
    venue: string;
    date: string;
    time: string;
  }>();
  const [numberData, setNumberData] = React.useState(0);

  const handleOpen = (datas: any, orderNumber: number) => {
    setOpen(true);
    setTicketData(datas.tickets);
    setNumberData(orderNumber);
    setEventData({
      name: datas.name,
      organizationName: datas.organization.name,
      venue:
        datas.venue.name +
        ", " +
        datas.venue.address1 +
        ", " +
        datas.venue.city,
      date: dayjs(datas.dateTime).tz(dayjs.tz.guess()).format("MMMM DD, YYYY"),
      time: dayjs(datas.dateTime).tz(dayjs.tz.guess()).format("hA"),
    });
  };
  return (
    <div>
      {open && (
        <Modal
          open={open}
          setOpen={setOpen}
          title={`${ticketData.length} Ticket(s)`}
          size="l"
        >
          <div className="mx-1  p-1 lg:mx-0 h-[650px] overflow-y-auto ">
            {ticketData.length > 0 ? (
              ticketData.map((e: Ticket, index) => {
                return (
                  <div className="min-h-[350px] max-w-[600px] mx-auto border border-gray-900 mb-2 p-5 font-['inter']">
                    <p className="text-sm font-bold text-center uppercase">
                      {eventData?.organizationName}
                    </p>
                    <h2 className="text-4xl font-bold text-center uppercase">
                      {eventData?.name}
                    </h2>
                    <p className="text-center text-[10px] tracking-tighter">
                      {eventData?.venue}
                    </p>
                    <div className="flex justify-center">
                      <div className="flex justify-between w-52 text-[10px]">
                        <p>{eventData?.date}</p>
                        <p>{eventData?.time}</p>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center mt-5">
                      {e.isClaimed && (
                        <div className=" flex items-center justify-center text-4xl lg:text-[35px] font-bold text-red-500">
                          <div className="relative flex items-center justify-center w-full h-full">
                            <div className="absolute  border-8  border-red-500 rounded-full top-[20px] bg-white bg-opacity-90 z-0 w-52 lg:w-52 h-52 lg:h-52 " />
                            <h1 className="absolute z-10 -rotate-45 lg:top-[110px] top-[105px]">
                              CLAIMED
                            </h1>
                          </div>
                        </div>
                      )}
                      <div className="flex justify-between w-52">
                        <p>{e.row}</p>
                        <p>{`${index + 1}/${ticketData.length}`}</p>
                      </div>
                      <QRCode value={e.code} size={200} />
                      {e.type && e.type !== "GENERAL_ADMISSION" ? (
                        <div className="flex justify-between w-52">
                          <p>Seat:{e.seat}</p>
                          <p>#{numberData}</p>
                        </div>
                      ) : (
                        <div className="flex justify-between w-52">
                          <p>#{numberData}</p>
                        </div>
                      )}
                    </div>
                    <p className="mt-5 text-lg font-bold leading-tight text-center">
                      PRESENT THIS CODE
                    </p>
                    <p className="text-lg font-bold leading-tight text-center">
                      ON THE DAY OF THE EVENT
                    </p>
                    <div className="mt-5 leading-[0] text-center">
                      <p className="text-sm font-['ClashDisplay'] font-semibold tracking-wide text-blue-1100">
                        Ticketnation
                      </p>
                      <a href="https://www.ticketnation.ph" className="text-xs">
                        www.ticketnation.ph
                      </a>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="relative h-96">
                <div className="absolute text-2xl transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                  No Tickets Available
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}

      <div className="relative flex flex-col items-center justify-center w-full overflow-hidden bg-blue-1100 h-96 rounded-bl-md rounded-br-md">
        {/* <div className="absolute top-0 right-0">
          <Logout />
        </div> */}
        <div className="flex flex-col items-center ">
          <div className="border-4 border-gray-300 rounded-full">
            <img src={ProfilePic} className="" alt="profile" />
          </div>
          <div className="mt-4 text-xl font-semibold tracking-wide text-white uppercase">
            {`${user?.firstName} ${user?.lastName}`}
          </div>
          <div className="font-light text-white">{user?.email}</div>
        </div>
      </div>
      {accessToken && <Order handleOpen={handleOpen} />}
    </div>
  );
};

export default ProfilePage;
