import React from "react";

interface ComponentProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  size?: "s" | "m" | "l" | "xl";
  children?: JSX.Element | JSX.Element[];
  id?: string;
  isEventInformation?: boolean;
  onClose?: () => void;
  fullHeight?: boolean;
}
export const Modal: React.FC<ComponentProps> = ({
  children,
  open,
  setOpen,
  title,
  size,
  isEventInformation,
  id = "modal",
  onClose,
  fullHeight,
}) => {
  const getSize = () => {
    switch (size) {
      case "m":
        return "lg:w-2/3";
      case "l":
        return "lg:w-11/12";
      case "xl":
        return "lg:w-full";
      default:
        return "lg:w-1/3";
    }
  };

  return (
    <>
      {open ? (
        <div
          id={id}
          aria-hidden="true"
          className={`absolute lg:fixed left-0 right-0 z-50 ${
            fullHeight ? "top-0 h-screen" : "top-20 max-h-[550px]lg:top-[80px]"
          } shadow-md flex justify-center items-center bg-gray-400 bg-opacity-50  md:inset-0 lg:mx-0 mx-2 z-[1000]`}
        >
          <div
            className={`flex h-full flex-col relative max-w-2xl  bg-white rounded-sm  ${getSize()} w-screen `}
          >
            <div className="flex items-center justify-between p-2 text-white bg-blue-1100">
              <span className="text-xl font-semibold uppercase lg:text-2xl">
                {title}
              </span>
              <button
                className="px-2 py-1 text-black bg-white border rounded-sm"
                onClick={() => {
                  if (onClose) onClose();
                  setOpen(false);
                }}
              >
                Close
              </button>
            </div>
            <div
              className={`flex-1 ${
                isEventInformation ? `relative overflow-y-auto` : ""
              }`}
            >
              {children}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
