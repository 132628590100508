import React from "react";
// @ts-ignore
import MessengerCustomerChat from "react-messenger-customer-chat";

const MessengerChat: React.FC = () => {
  return (
    <MessengerCustomerChat pageId="101050544777314" appId="398188149182544" />
  );
};

export default MessengerChat;
