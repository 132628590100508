import React from "react";
// import ProfileVector from "../../../assets/images/ProfileVector.png";
import { useAppSelector } from "../../../app/hooks";
import { authSelect } from "../../../features/Authentication/authSlice";
import { Link, NavLink } from "react-router-dom";
import { Logout } from "../../../features";
import { useWindowSize } from "../../../helpers/customHooks";
import TnLogoBlue from "../../../assets/images/tnlogoblue.png";
import TnLogowhite from "../../../assets/images/tnlogowhite.png";

const Navbar = () => {
  const [open, setOpen] = React.useState(false);
  const [width, height] = useWindowSize();
  const { user } = useAppSelector(authSelect);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div className="sticky top-0 z-[100] flex flex-col">
      <div className="flex items-center justify-between h-20 px-6 py-4 bg-blue-1100 lg:bg-white text-blue-1100">
        <Link to="/">
          <div className="hidden w-64 text-blue-1100 lg:block">
            <img
              className="h-48"
              src={`${TnLogoBlue}`}
              alt="ticketnationlogo"
            />
            {/* <svg
              className="text-blue-1100"
              width="227"
              height="21"
              viewBox="0 0 227 21"
              fill={width >= 768 ? "#004E92" : "#fbfbfb"}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M20.016 0.0566211V4.69662H12.432V16.3606H7.712V4.69662H0.8V0.0566211H20.016ZM26.6685 0.376621V16.6806H22.2525V0.376621H26.6685ZM33.3081 11.9126H48.1241V16.6806H28.9081V0.376621H48.1241V5.01662H33.3081V11.9126ZM59.2086 6.24862L59.2566 6.20062L60.4406 5.01662L65.0806 0.376621H59.3526L54.7606 5.01662V0.376621H50.3606V5.01662V6.20062V16.6806H54.7606V10.5526H57.7046L59.0806 11.9126L63.8486 16.6806H69.6246L59.2086 6.24862ZM91.076 0.376621H71.86V5.01662H91.076V0.376621ZM71.86 16.6806H91.076V11.9126H76.26V10.5526H91.076V6.36062H71.86V16.6806ZM112.528 0.0566211V4.69662H104.944V16.3606H100.224V4.69662H93.3125V0.0566211H112.528ZM133.901 0.184621V19.2886L119.165 8.13662V16.4886H114.765V0.184621H118.493L129.261 8.66462V0.184621H133.901ZM136.139 0.184621V4.82462H150.875V6.02462V6.16862H136.139V6.02462V16.5046H140.539V10.3606H150.875V16.5046L155.275 20.9046V0.184621H136.139ZM176.73 0.0566211V4.69662H169.146V16.3606H164.426V4.69662H157.514V0.0566211H176.73ZM183.382 0.376621V16.6806H178.966V0.376621H183.382ZM185.622 0.248621V16.4246H204.838V0.248621H185.622ZM200.358 11.8486H190.086V4.82462H200.358V11.8486ZM226.21 0.184621V19.2886L211.474 8.13662V16.4886H207.074V0.184621H210.802L221.57 8.66462V0.184621H226.21Z" />
            </svg> */}
          </div>
          <div className="block w-64 text-blue-1100 lg:hidden">
            <img
              className="h-36"
              src={`${TnLogowhite}`}
              alt="ticketnationlogo"
            />
          </div>
        </Link>

        <div className="flex items-center justify-center">
          <div className="hidden lg:block">
            <div className="flex items-center">
              <div className="flex">
                <div>
                  <NavLink
                    id="nav-home"
                    end
                    to="/"
                    className={({ isActive }) => (isActive ? "font-bold" : "")}
                  >
                    <div className="px-5 hover:scale-110">HOME</div>
                  </NavLink>
                </div>
                <NavLink
                  id="nav-partner"
                  to="/partner"
                  className={({ isActive }) => (isActive ? "font-bold" : "")}
                >
                  <div className="px-5 hover:scale-110">BE A PARTNER</div>
                </NavLink>
                <NavLink
                  id="nav-about"
                  to="/about-us"
                  className={({ isActive }) => (isActive ? "font-bold" : "")}
                >
                  <div className="px-5 hover:scale-110">ABOUT US</div>
                </NavLink>
                <NavLink
                  id="nav-artist"
                  to="/artist"
                  className={({ isActive }) => (isActive ? "font-bold" : "")}
                >
                  <div className="px-5 hover:scale-110">ARTIST</div>
                </NavLink>
              </div>
              {user !== null && (
                <>
                  <NavLink
                    to="/profile"
                    className={({ isActive }) => (isActive ? "font-bold" : "")}
                  >
                    <div className="px-5 hover:scale-110">PROFILE</div>
                  </NavLink>
                  <div className="flex justify-start m-2">
                    <div className="w-full p-1 bg-red-400 rounded-sm lg:min-w-[24px]">
                      <Logout />
                    </div>
                  </div>
                </>
              )}
              {user === null && (
                <Link to="/signin">
                  <button
                    id="nav-signin-button"
                    className="w-24 p-1 text-white rounded-sm bg-blue-1100 hover:scale-110"
                  >
                    SIGN IN
                  </button>
                </Link>
              )}
            </div>
          </div>

          <button
            className="block text-2xl text-white lg:hidden"
            onClick={() => handleToggle()}
          >
            <i className="fa fa-bars"></i>
          </button>
        </div>
      </div>

      {open ? (
        <div className="flex flex-col text-white bg-blue-1100 lg:hidden">
          <div className="flex flex-col lg:p-5 lg:items-center lg:justify-between lg:flex-row">
            <div>
              <Link id="nav-home-mobile" to="/" onClick={() => setOpen(false)}>
                <div className="m-1 lg:m-0 lg:px-5">HOME</div>
              </Link>
            </div>
            <div>
              <Link
                id="nav-partner-mobile"
                to="/partner"
                onClick={() => setOpen(false)}
              >
                <div className="m-1 lg:m-0 lg:p-3">BE A PARTNER</div>
              </Link>
            </div>
            <div>
              <Link
                id="nav-about-mobile"
                to="/about-us"
                onClick={() => setOpen(false)}
              >
                <div className="m-1 lg:m-0 lg:p-3">ABOUT US</div>
              </Link>
            </div>
            <div>
              <Link
                id="nav-artist-mobile"
                to="/artist"
                onClick={() => setOpen(false)}
              >
                <div className="m-1 lg:m-0 lg:p-3">ARTIST</div>
              </Link>
            </div>
          </div>
          {user !== null && (
            <>
              <Link to="/profile" onClick={() => setOpen(false)}>
                <div className="m-1 lg:m-0 lg:p-3">PROFILE</div>
              </Link>
              <div className="flex justify-start m-2">
                <div className="w-full p-1 bg-red-400 rounded-sm lg:w-24">
                  <Logout />
                </div>
              </div>
            </>
          )}
          {user === null && (
            <Link id="nav-signin-mobile" to="/signin">
              <div className="flex justify-start m-2">
                <button className="w-full p-1 bg-white rounded-sm text-blue-1100 tex lg:w-24">
                  SIGN IN
                </button>
              </div>
            </Link>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Navbar;
