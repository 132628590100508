import React from "react";
import { toast } from "react-toastify";
import UBLogo from "../../assets/images/UB-Logo.png";
import BPILogo from "../../assets/images/BPI-Logo.png";
import RCBCLogo from "../../assets/images/RCBC-Logo.png";
import {
  CustomError,
  useDebitCardPaymentMutation,
  useInitDebitCardPaymentMutation,
  useValidateDebitOtpMutation,
} from "../../services/payment.api";
import Loading from "../../components/Loading";
import { Modal } from "../../components/Modal";
import io from "socket.io-client";
import { useNavigate } from "react-router-dom";
import OTPValidation from "../../components/OTPValidation";
import { useAppSelector } from "../../app/hooks";
import { authSelect } from "../Authentication/authSlice";
const socket = io(process.env.REACT_APP_BASE_URL || "http://localhost:4000");

interface ComponentProps {
  orderId: string;
  amount: number;
}

const OnlineDebit: React.FC<ComponentProps> = ({ orderId, amount }) => {
  const { user } = useAppSelector(authSelect);
  const navigate = useNavigate();
  const [initDebitCardPayment, { isError, isLoading, data, error }] =
    useInitDebitCardPaymentMutation();
  const [
    debitCardPayment,
    {
      isError: ddIsError,
      isLoading: ddIsLoading,
      data: ddData,
      error: ddError,
    },
  ] = useDebitCardPaymentMutation();
  const [
    otpValidation,
    {
      isError: otpIsError,
      isLoading: otpIsLoading,
      isSuccess: otpSuccess,
      error: otpError,
    },
  ] = useValidateDebitOtpMutation();

  const [open, setOpen] = React.useState(false);
  const [paymentResponse, setPaymentResponse] = React.useState<{
    event: string;
    id: string;
    business_id: string;
    reference_id: string;
    payment_method_id: string;
    channel_code: string;
    currency: string;
    amount: number;
    description: string;
    status: string;
    metadata: null;
    timestamp: string;
    created: string;
    updated: string;
  }>();
  const [response, setResponse] = React.useState<
    | {
        event: string;
        id: string;
        channel_code: string;
        type: string;
        timestamp: string;
        accounts: {
          id: string;
          account_details: string;
          account_hash: string;
          currency: string;
          account_type: string;
          description: string;
        }[];
      }
    | undefined
  >();

  const customError = (error || ddError || otpError) as CustomError;

  React.useEffect(() => {
    if (ddData) {
      setOpen(true);
      toast.info("Pending Payment", {
        autoClose: 3000,
      });
    }
  }, [ddData]);

  React.useEffect(() => {
    if (otpSuccess) {
      setOpen(true);
      toast.success("Otp Validated Successfully", {
        autoClose: 3000,
      });
    }
  }, [otpSuccess]);

  React.useEffect(() => {
    if (isError || ddIsError || otpIsError) {
      toast.error("Error Payment", {
        autoClose: 3000,
      });
    }
  }, [ddIsError, isError, otpIsError]);

  React.useEffect(() => {
    if (data) {
      setOpen(true);
    }
  }, [data]);

  React.useEffect(() => {
    if (paymentResponse?.status === "COMPLETED") {
      setOpen(false);
      window.fbq("track", "Purchase", {
        value: amount,
        currency: "PHP",
        em: user?.email ? user?.email.toLowerCase() : "",
        ln: user?.lastName ? user?.lastName.toLowerCase() : "",
        fn: user?.firstName ? user?.firstName.toLowerCase() : "",
      });
      navigate("success");
      toast.success("Payment Successful", {
        autoClose: 3000,
      });
    }
  }, [paymentResponse]);

  React.useEffect(() => {
    if (response) {
      debitCardPayment({
        referenceId: orderId.toString(),
        tokenId: response?.id || "",
        amount,
      });
      setOpen(false);
    }
    socket.emit("connected", "paymentInit");
    socket.on("ddPaymentStatusNotif", (data) => {
      setPaymentResponse(data);
    });
  }, [response]);

  if (isLoading || ddIsLoading || otpIsLoading) {
    return <Loading />;
  }

  const handleClick = (channelCode: string) => {
    socket.emit("connected", "paymentInit");
    socket.on("ddPaymentNotif", (resp) => {
      setResponse(resp);
    });
    initDebitCardPayment({
      channelCode,
      referenceId: orderId.toString(),
      channelProperties: {
        failure_redirect_url: "https://redirect.me/failed",
        success_redirect_url: "https://redirect.me/success",
      },
    });
  };

  return (
    <div>
      {isError && (
        <div className="my-5 text-red-500">{customError.data.message}</div>
      )}
      <div className="flex justify-center gap-3 pt-4">
        <button
          onClick={() => handleClick("BA_BPI")}
          className="px-3 py-1 border rounded-md"
        >
          <div
            className="px-5 py-3 bg-center bg-no-repeat bg-contain "
            style={{ backgroundImage: `url(${BPILogo})` }}
          >
            <div className="w-8 h-6" />
          </div>
        </button>
        <button
          onClick={() => handleClick("BA_UBP")}
          className="px-3 py-1 border rounded-md pointer-events-none"
        >
          <div
            className="px-10 py-3 bg-center bg-no-repeat bg-contain opacity-50 pointer-events-none"
            style={{ backgroundImage: `url(${UBLogo})` }}
          >
            <div className="w-8 h-6" />
          </div>
        </button>
        <button
          onClick={() => handleClick("BA_RCBC")}
          className="px-3 py-1 border rounded-md pointer-events-none"
        >
          <div
            className="px-5 py-3 bg-center bg-no-repeat bg-contain opacity-50 pointer-events-none"
            style={{ backgroundImage: `url(${RCBCLogo})` }}
          >
            <div className="w-8 h-6" />
          </div>
        </button>
      </div>

      <Modal open={open} setOpen={setOpen} size="xl">
        {ddData ? (
          <>
            {ddData?.is_otp_required ? (
              <OTPValidation
                onSubmit={(otp) => {
                  otpValidation({
                    directDebitID: ddData?.id || "",
                    otp,
                  });
                }}
              />
            ) : (
              <iframe
                src={ddData?.checkout_url || ""}
                className="w-full h-[650px] overflow-y-scroll"
              />
            )}
          </>
        ) : (
          <iframe
            src={data?.authorizer_url}
            className="w-full h-[650px] overflow-y-scroll"
          />
        )}
      </Modal>
    </div>
  );
};

export default OnlineDebit;
