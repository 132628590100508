import dayjs from "dayjs";
import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Error from "../../../components/Error";
import Loading from "../../../components/Loading";
import { useGetEventQuery } from "../../../services/event.api";
import { EventDetails } from "./EventDetails";
import { EventTickets } from "./EventTickets";
import Countdown from "react-countdown";
import { useAppDispatch } from "../../../app/hooks";
import { setSelectedPayload } from "./eventSlice";
import OrderContext from "../../../pages/Order/context/OrderContext";

interface ComponentProps {
  id: string;
  dateDropDown?: JSX.Element;
}
export const SelectedEvent: React.FC<ComponentProps> = ({
  id,
  dateDropDown,
}) => {
  const { data: event, isLoading, isError } = useGetEventQuery(id);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(setSelectedPayload([]));
  }, [dispatch]);

  if (isLoading) return <Loading />;
  if (!event) return null;
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: any;
    hours: any;
    minutes: any;
    seconds: any;
    completed: any;
  }) => {
    if (completed) {
      // Render a completed state
      return <div>Complete</div>;
    } else {
      // Render a countdown
      return (
        <div className="flex">
          <div className="flex flex-col items-center mx-2">
            <span className="text-4xl">{days}</span>
            <span>days</span>
          </div>
          <div className="flex flex-col items-center mx-2">
            <span className="text-4xl">{hours}</span>
            <span>hours</span>
          </div>
          <div className="flex flex-col items-center mx-2">
            <span className="text-4xl">{minutes}</span>
            <span>minutes</span>
          </div>
          <div className="flex flex-col items-center mx-2">
            <span className="text-4xl">{seconds}</span>
            <span>seconds</span>
          </div>
        </div>
      );
    }
  };
  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Ticketnation Philippines | {event?.name} | {event?.venue.name}
        </title>
        <link
          rel="canonical"
          href={`https://ticketnation.ph/event/${event?.slug}`}
        />
        <meta name="og:title" content={event?.name} />
        <meta
          name="og:url"
          content={`https://ticketnation.ph/event/${event?.slug}`}
        />
        <meta
          name="og:image"
          content={
            event?.image
              ? process.env.REACT_APP_IMAGE_BASE_URL + event.image.fileName
              : ""
          }
        />
        <meta
          name="og:description"
          content={dayjs(event?.dateTime).format("MMMM DD, YYYY hh:mm A")}
        />
        <meta name="og:type" content="event" />
      </Helmet>

      {event && dayjs(event?.start) > dayjs() && (
        <div className="absolute top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center min-h-screen bg-black bg-opacity-40">
          <div className="flex flex-col p-4 bg-white rounded-md">
            <p className="text-2xl">Selling starts in:</p>
            <Countdown date={event?.start} renderer={renderer} />
            <Link
              to="/"
              className="px-2 py-1 mt-4 font-semibold text-center text-white rounded-md bg-blue-1100"
            >
              Back to Home
            </Link>
          </div>
        </div>
      )}
      {event && dayjs(event?.end) < dayjs() && (
        <div className="absolute top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center min-h-screen bg-black bg-opacity-40">
          <div className="flex flex-col p-4 bg-white rounded-md">
            <p className="text-4xl text-red-500">Event already started!</p>
            <Link
              to="/"
              className="px-2 py-1 mt-4 font-semibold text-center text-white rounded-md bg-blue-1100"
            >
              Back to Home
            </Link>
          </div>
        </div>
      )}
      {event ? (
        <>
          <EventDetails event={event} dateDropDown={dateDropDown} />
          <EventTickets
            dateDropDown={dateDropDown}
            eventTickets={event.eventTickets}
            id={event.id}
            orderFee={event.eventOrderFee}
            currency={event.currency}
          />
        </>
      ) : (
        <div>
          <h2 className="text-2xl">Event not found.</h2>
          <Link to="/" className="text-blue-500 underline">
            Back to Home
          </Link>
        </div>
      )}
    </div>
  );
};
