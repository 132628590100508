import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Error from "../../components/Error";
import { Modal } from "../../components/Modal";
import {
  handleXenditInitiation,
  handleXenditVerification,
} from "../../helpers/xendit";
import {
  CustomError,
  useCreateCreditCardPaymentMutation,
} from "../../services/payment.api";
import { authSelect } from "../Authentication/authSlice";

interface FormValues {
  name_on_card: string;
  card_number: string;
  card_exp_month: string;
  card_exp_year: string;
  card_cvn: string;
  is_multiple_use: boolean;
  should_authenticate: boolean;
}

interface ComponentProps {
  orderId: string;
  amount: number;
  acknowledgement?: boolean;
}

export const CreditCard: React.FC<ComponentProps> = ({
  orderId,
  amount,
  acknowledgement,
}) => {
  const { user } = useAppSelector(authSelect);
  const navigate = useNavigate();
  const [cardToken, setCardToken] = useState("");
  const [tokenStatus, setTokenStatus] = useState("");
  const [cvn, setCvn] = useState("");
  const [open, setOpen] = useState(false);
  const [createCreditCardPayment, { isSuccess, isError, error }] =
    useCreateCreditCardPaymentMutation();

  React.useEffect(() => {
    handleXenditInitiation();
  }, []);

  React.useEffect(() => {
    if (isSuccess) {
      window.fbq("track", "Purchase", {
        value: amount,
        currency: "PHP",
        em: user?.email ? user?.email.toLowerCase() : "",
        ln: user?.lastName ? user?.lastName.toLowerCase() : "",
        fn: user?.firstName ? user?.firstName.toLowerCase() : "",
      });
      navigate("success");
    }
  }, [isSuccess, navigate]);

  React.useEffect(() => {
    if (tokenStatus === "Verified") {
      setOpen(false);
      createCreditCardPayment({
        orderId,
        tokenId: cardToken,
        cardCVN: cvn,
      });
    }
  }, [tokenStatus, orderId, cvn, cardToken, createCreditCardPayment]);

  const initialValues: FormValues = {
    name_on_card: "",
    card_number: "",
    card_exp_month: "",
    card_exp_year: "",
    card_cvn: "",
    is_multiple_use: false,
    should_authenticate: true,
  };

  const validationSchema = Yup.object().shape({
    card_number: Yup.string().required("Card Number is required."),
    card_exp_year: Yup.string()
      .min(4)
      .max(4)
      .required("Card expiry year doesn't follow format: YYYY"),
    card_exp_month: Yup.string()
      .min(2)
      .max(2)
      .required("Card expiry month doesn't follow format: MM"),
  });

  function xenditResponseHandler(err: string, creditCardCharge: any) {
    if (err) {
      // Show the errors on the form
      console.log(err);
      return;
    }

    if (creditCardCharge.status === "VERIFIED") {
      const token = creditCardCharge.id;
      setCardToken(token);
      setTokenStatus("Verified");
    } else if (creditCardCharge.status === "IN_REVIEW") {
      const authenticationUrl = creditCardCharge.payer_authentication_url;
      setCardToken(authenticationUrl);
      setTokenStatus("In Review");
      setOpen(true);
    } else if (creditCardCharge.status === "FAILED") {
      setCardToken(creditCardCharge.failure_reason);
      setTokenStatus("Failed");
    }
  }

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    window.fbq("track", "InitiateCheckout", {
      em: user?.email ? user?.email.toLowerCase() : "",
      ln: user?.lastName ? user?.lastName.toLowerCase() : "",
      fn: user?.firstName ? user?.firstName.toLowerCase() : "",
    });
    const isCardValidValid = handleXenditVerification(
      "card_number",
      values.card_number
    );
    const isCardExpiryValid = handleXenditVerification(
      "expiry_date",
      values.card_exp_month,
      values.card_exp_year
    );
    const isCardCvnValid = handleXenditVerification(
      "cvn_number",
      values.card_cvn
    );

    if (isCardValidValid && isCardExpiryValid && isCardCvnValid) {
      setCvn(values.card_cvn);
      window.Xendit.card.createToken(
        {
          amount,
          ...values,
        },
        xenditResponseHandler
      );
    }
  };

  if (!acknowledgement) {
    return (
      <div className="text-red-500 ">
        Please acknowledge the terms and conditions to proceed.
      </div>
    );
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, values }) => {
          return (
            <Form>
              {isError &&
                (error as CustomError).data.message.length > 0 &&
                (error as CustomError).data.message.map((mess) => {
                  return <p className="text-red-700">{mess}</p>;
                })}
              {errors && (
                <div>
                  <p className="text-red-700">{errors.card_number}</p>
                  <p className="text-red-700">{errors.card_exp_month}</p>
                  <p className="text-red-700">{errors.card_exp_year}</p>
                </div>
              )}
              <div className="w-full mt-4">
                <Field name="name_on_card">
                  {({ field }: FieldProps) => (
                    <input
                      type="text"
                      placeholder="Name on Card"
                      className="w-full px-4 py-2 border-black drop-shadow border-1"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="w-full mt-4">
                <Field name="card_number">
                  {({ field, form: { setFieldValue } }: FieldProps) => (
                    <input
                      type="text"
                      placeholder="Card Number"
                      className="w-full px-4 py-2 border-black drop-shadow input-number-no-appearance border-1"
                      {...field}
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        const regex =
                          /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                        if (!value || regex.test(value.toString())) {
                          setFieldValue("card_number", value);
                        }
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className="grid grid-cols-2 gap-6">
                <div className="grid w-full grid-cols-2 gap-2 mt-4">
                  <Field name="card_exp_month">
                    {({ field }: FieldProps) => (
                      <input
                        type="text"
                        placeholder="Expiry Month - MM"
                        className="w-full px-4 py-2 border-black drop-shadow border-1"
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="card_exp_year">
                    {({ field }: FieldProps) => (
                      <input
                        type="text"
                        placeholder="Expiry Year - YYYY"
                        className="w-full px-4 py-2 border-black drop-shadow border-1"
                        {...field}
                      />
                    )}
                  </Field>
                </div>
                <div className="w-full mt-4">
                  <Field name="card_cvn">
                    {({ field }: FieldProps) => (
                      <input
                        type="text"
                        placeholder="CVN"
                        className="w-full px-4 py-2 border-black drop-shadow border-1"
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className="w-full my-6">
                <button
                  type="submit"
                  className={`w-full p-2 font-semibold text-white uppercase bg-blue-1100`}
                >
                  CONFIRM PAYMENT
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Modal open={open} setOpen={setOpen} size="l">
        <iframe
          title="3DS Authentication"
          src={cardToken}
          height={500}
          width="100%"
        />
      </Modal>
    </>
  );
};
