import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import Ellipsis from "react-ellipsis-text";
import Timezone from "dayjs/plugin/timezone";
import UTC from "dayjs/plugin/utc";
import fire from "../../assets/images/fire.gif";

dayjs.extend(UTC);
dayjs.extend(Timezone);

interface EventProps {
  title: string;
  venue?: string;
  title_caption?: string;
  date: string;
  description?: string;
  image: string;
  id: string;
  slug: string;
  link?: string;
  isGroup?: boolean;
  isAuth?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  discount?: number;
  isHotDeal?: boolean;
}

export const EventCard: React.FC<EventProps> = ({
  title,
  venue,
  title_caption,
  date,
  description,
  image,
  id,
  slug,
  link = "event",
  isGroup,
  discount,
  isHotDeal,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="relative flex flex-col bg-white rounded drop-shadow"
      key={id}
      id={`event-${slug}`}
    >
      <img
        className="h-[250px] w-full lg:h-[300px] lg:w-full object-cover"
        src={image}
        alt="eventpic"
      />
      <div className="flex flex-col flex-1 px-1 lg:px-2">
        <div className="flex flex-col justify-between flex-1 text-base lg:text-xl">
          <span className="font-semibold text-blue-1100">{title}</span>
          <span className="text-sm text-gray-700 lg:text-base">{venue}</span>
          <span className="text-xs text-gray-500 lg:text-sm">
            <Ellipsis text={title_caption} length={15} />
          </span>
          <div className="flex flex-col justify-end flex-1">
            <button
              onClick={() => {
                window.fbq("trackCustom", "SelectEvent");
                navigate(`/${link}/${slug}`);
              }}
              className="p-1 mb-1 font-semibold text-white border rounded-sm lg:p-2 drop-shadow bg-blue-1100 hover:border-blue-1100 hover:bg-white hover:text-blue-1100"
            >
              {isGroup ? "VIEW EVENTS" : "BUY TICKETS"}
            </button>
          </div>
        </div>
      </div>
      {!isGroup && (
        <div className="absolute top-0 w-[70px] text-white rounded-b-sm bg-blue-1100 lg:w-20 drop-shadow right-2">
          <div className="flex flex-col items-center justify-center w-full text-xl lg:text-2xl">
            <div className="text-base leading-tight lg:text-lg">
              {dayjs(date).tz(dayjs.tz.guess()).format("ddd")}
            </div>
            <div className="text-2xl font-semibold leading-tight lg:text-4xl">
              {dayjs(date).tz(dayjs.tz.guess()).format("DD")}
            </div>
            <div className="text-sm leading-tight lg:text-base">
              {dayjs(date).tz(dayjs.tz.guess()).format("MMM YYYY")}
            </div>
            <div className="text-xs leading-tight lg:text-sm">
              {dayjs(date).tz(dayjs.tz.guess()).format("hh:mm A")}
            </div>
          </div>
        </div>
      )}
      {discount && discount > 0 ? (
        <div className="absolute flex flex-col items-center justify-center w-24 h-24 p-4 text-white bg-red-500 rounded-full left-1 top-1 bg-opacity-80 ">
          <span className="text-xs text-center">DISCOUNT UP TO</span>
          <span id="discount" className="text-2xl font-semibold">
            {discount}%
          </span>
        </div>
      ) : (
        ""
      )}
      {isHotDeal ? (
        <div className="hotdeals absolute top-8 lg:top-14 right-20  pr-2">
          <p>
            <span className="fires  ">
              <span className="flames ">
                <div className="absolute ml-2 w-[70px] h-[70px]">
                  <img
                    src={fire}
                    alt="My GIF"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
                <p className="absolute ml-1 top-16 text-sm flex flex-row gap-1 font-extrabold  md:font-extrabold shadow-xl text-red-500 shadow-black  ">
                  <p>HOT</p>
                  <p>DEALS </p>
                </p>
              </span>
            </span>
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
