import { Inquiry, CreateInquiry } from '../models/inquiry.model';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Inquiries {
  data: Inquiry[];
  skip: number;
  take: number;
  count: number;
}

interface InquiriesQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const inquiryApi = createApi({
  reducerPath: "inquiryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/public/inquiries",
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Inquiry", "Inquiries"],
  endpoints: (builder) => ({
    createInquiry: builder.mutation<Inquiry, CreateInquiry>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useCreateInquiryMutation } = inquiryApi;
